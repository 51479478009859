export default {
  computed: {
    earningsModel() {
      return {
        complex: {
          type: 'complex',
          width: 160,
          detailedQuery: true,
          options: [
            {
              label: this.$t('main.platform'),
              value: {
                type: 'link',
                field: 'url',
                group: 'host',
                detailedKey: 'id',
              },
            },
            {
              label: this.$t('common.country'),
              value: {
                type: 'country',
                field: 'country',
                group: 'country',
                setNestedGroup: 'host',
              },
            },
          ],
        },
        bids: {
          type: 'common',
          label: this.$t('main.credits'),
          width: 100,
          sortable: true,
        },
        cost: {
          type: 'common',
          label: this.$t('common.sumRub'),
          width: 120,
          sortable: true,
        },
        cpm: {
          type: 'common',
          label: 'CPM, ₽',
          width: 110,
          sortable: true,
        },
      };
    },
  },
};
